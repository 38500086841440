:root {
  /*   color variables */
  --clr-primary: #81d4fa;
  --clr-primary-light: #e1f5fe;
  --clr-primary-dark: #4fc3f7;
  --clr-gray100: #f9fbff;
  --clr-gray150: #f4f6fb;
  --clr-gray200: #eef1f6;
  --clr-gray300: #e1e5ee;
  --clr-gray400: #767b91;
  --clr-gray500: #4f546c;
  --clr-gray600: #2a324b;
  --clr-gray700: #161d34;
  --clr-pending: #fff0c2;
  --clr-pending-font: #a68b00;
  --clr-error: #ffcdd2;
  --clr-error-font: #c62828;
  --clr-success: #c8e6c9;
  --clr-success-font: #388e3c;
  --clr-primary: rgba(46, 78, 204, 1);
  --clr-primary-hover: rgba(46, 78, 204, 0.9);
  --clr-primary-active: rgba(46, 78, 204, 0.6);
  --clr-download: rgba(45, 45, 45, 1);
  --clr-download-hover: rgba(45, 45, 45, 0.9);
  --clr-download-active: rgba(45, 45, 45, 0.6);
  --clr-delete: rgba(236, 55, 81, 1);
  --clr-delete-hover: rgba(236, 55, 81, 0.9);
  --clr-delete-active: rgba(236, 55, 81, 0.4);
  --clr-clr-link: #2962ff;

  /*   border radius */
  --radius: 0.2rem;

}

/* html{
  height: 100%;
} */

.sympl-button{
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 5rem;
  border-width: 0;
  margin: 2px;
  transition: .3s;
}

body{
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.table-body {
  font-family: Poppins, sans-serif;
  height: 60vh;
  display: grid;
  justify-content: center;
  align-items: center;
  color: var(--clr-gray500);
  font-size: 0.9rem;
}



.box-title{
  margin-bottom: 25px;
}

table{
  border-collapse: collapse;
  box-shadow: 0 5px 10px var(--clr-gray300);
  background-color: white;
  text-align: left;
  overflow: hidden;
  border-radius: 15px;
}

.main-table{
  counter-reset: serial-number;
}

.main-table td:first-child:before {
  counter-increment: serial-number;  /* Increment the serial number counter */
  content: counter(serial-number);  /* Display the counter */
}

thead{
  box-shadow: 0 5px 10px var(--clr-gray300);
}

th{
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;
  font-weight: 900;
  text-align: center;
}

td{
  padding: 1rem 2rem;
}

.status{
  border-radius: var(--radius);
  text-transform: uppercase;
  background-color: rgb(194, 216, 218);
  padding: 0.5rem 1.5rem;
  text-align: center;
  align-items: center;
  display:block;
  margin:0 auto;
}

.status-success{
  background-color: var(--clr-success);
  color: var(--clr-success-font);
}

.status-pending{
  background-color: var(--clr-pending);
  color: var(--clr-pending-font);
}

.status-error{
  background-color: var(--clr-error);
  color: var(--clr-error-font);
}

tr:nth-child(even){
  background-color: #f8f8f8;
}

.no-data{
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
}

.center{
  text-align: center;
}

footer{
  padding-top: 20px;
  padding-bottom: 25px;
  text-align: center;
  background-color: #080808;
  color: #FFFFFF;
  margin-top: auto;
}

.footer-link{
  text-decoration: none;
  color: #FFFFFF;
}

.submit-section{
  margin-top: 100px;
}

.add-link-bg{
  background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgb(255, 255, 255)), url("link_bg.png");
  background-size: 2000px;
}

.all-list-section{
  margin-top: 25px;
  margin-bottom: 100px;
}

.download-all-button{
  margin-right: 0.5rem;
  background-color: var(--clr-download);
  color: #FFF;
}

/* .clear-all-button{
  margin-left: 0.5rem;
  background-color: var(--clr-delete);
  color: #FFF;
} */

.download-all-button:hover{
  box-shadow: 0 0 0 .2rem var(--clr-download-active);
  color: #FFF;
}

.download-all-button:active{
  background-color: var(--clr-download-hover);
  box-shadow: 0 0 0 .2rem var(--clr-download-active);
}

.clear-all-button:hover{
  box-shadow: 0 0 0 .2rem var(--clr-delete-active);
  color: #FFF;
}

.clear-all-button:active{
  background-color: var(--clr-delete-hover);
  box-shadow: 0 0 0 .2rem var(--clr-delete-active);
}

.add-link-button{
  color: #fff;
  background-color: var(--clr-primary);
}

.add-link-button:hover{
  color: #fff;
  background-color: var(--clr-primary-hover);
}

.add-link-button:active{
  color: #fff;
  box-shadow: 0 0 0 .2rem var(--clr-primary-active);
}

.more-option-main{
  display: flex;
  justify-content: center;
}

.table-action{
  width:150px;
  text-align: center;
}

.sympl-button-action{
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: .5rem;
  border-width: 0;
  margin: 2px;
  transition: .3s;
  background-color: #F0F0F0;
}

.table-action-download{
  /* background-color: transparent; */
  display: inline-block;
  border-width: 0;
  font-size: 1.3rem;
  margin-right: 5px;
  color: #000;
}

.table-action-copy{
  /* background-color: transparent; */
  display: inline-block;
  border-width: 0;
  font-size: 1.3rem;
  margin-right: 5px;
  color: #000;
}


.table-action-delete{
  /* background-color: transparent; */
  display: inline-block;
  border-width: 0;
  font-size: 1.3rem;
  color: #000;
}

.table-action-download:hover{
  color: #4e68d3;
}

.table-action-copy:hover{
  color: #32B959;
}


.table-action-delete:hover{
  color: #E63946;
}



.sticky{
  position:fixed;
  bottom: 7rem;
}

.menu-wrapper{
  overflow: hidden;
  padding: 5px;
  padding-bottom: 10px;
}

.list-title{
  float:left;
}

.more-option{
  float:right;
}

.add-main{
  display:flex;
  justify-content:center;
  align-items:center;
}

.entry-box{
  background-color: #2E4ECC;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
  display: flex;
}

.entry-box .add-button{
  width: 45px;
  height: 45px;
  outline: none;
  border: 0;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s;
}

.entry-box .add-button:hover{
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0 .4rem rgba(0, 0, 0, 0.2);
}

.entry-box .add-button:active{
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 .4rem rgba(0, 0, 0, 0.2);
}

.book-icon{
  color: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #fff;
  /* margin-left: 15px; */
}

.entry-box .input_add{
  outline: none;
  border: 0;
  background-color:#fff;
  color: #000;
  padding: 15px 20px;
  width: 600px;
  height: 45px;
  border-radius: 50px;
}

::placeholder{
  color: #C5C5C5;
}

::-webkit-input-placeholder{
  color: #C5C5C5;
}

.nav-link.active {
  color: #2E4ECC;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar{
  width: .60rem;
  background-color: #414141;
}

::-webkit-scrollbar-thumb{
  background-color: #2E4ECC;
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #213a9c;
  border-radius: .5rem;
}

.heart-icon{
  color: rgb(255, 0, 0);
}

.wink-icon{
  color: rgb(255, 230, 0);
}

.footer-copyright{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.carousel-item{
  text-align: center;
}

.did-you-know{
  font-size: .8rem;
  padding-top: 50px;
  padding-bottom: 50px;
}

.did-you-know h5{
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  padding-bottom: 1rem;
}

.did-you-know p{
  font-size: 1.3rem;
}

.Table-Data-Title{
  font-weight: 600;
  transition: .3s;
}

.Table-Data-Title:hover{
  cursor: pointer;
  color: #213a9c;
}

.Table-Data-Title:active{
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.Table-Data-Doi{
  font-weight: 600;
  transition: .3s;
}

.Table-Data-Doi:hover{
  cursor: pointer;
  color: #213a9c;
}

.Table-Data-Doi:active{
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.Main-title{
  text-align: center;
  padding-top: 2rem;
  font-size: 4rem;
  font-weight: 600;
}

.Main-title-alt{
  color: #213a9c;
}

.visitor-count{
  margin-top: 25px;
  padding-top: 75px;
  padding-bottom: 75px;
  width: 100%;
  background-image: linear-gradient( rgba(255, 255, 255,1),rgba(255, 255, 255, 0.9)), url("visitor-count.png");
  background-size: 2000px;
}

.visitor-count-text{
  text-align: center;
}

.visitor-count-value{
  text-align: center;
  font-size: 4rem;
  font-weight: 800px;
}

.loading{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.mobile-img{
  height: 40vh;
  margin-top: 50px;
  margin-bottom: 25px;
}

.mobile-section{
  visibility: hidden;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-control{
  border-color: rgb(212, 212, 212);
}

.form-group{
  padding-top: 25px;
  padding-bottom: 25px;
}

.suggest-section{
  padding-top: 25px;
}

.form-section {
  padding-left: 75px;
  padding-right: 75px;
}

@media screen and (max-width: 1000px) {
  .mobile-section{
    visibility: visible;
    display: block;
  }
  .main-block{
    visibility: hidden;
    display: none;
  }
}

.no-data-text{
  color: #EC3751;
}